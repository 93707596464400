import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

const spin = keyframes`
  to { -webkit-transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 100px;
  height: 100px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #4fb58d;
  animation: ${spin} 1s ease-in-out infinite;
`;

export default Spinner;
