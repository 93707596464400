import styled from "@emotion/styled";

const BlisterPhoto = styled.img`
  width: 100vw;
  margin-left: -30px;
  height: 40vh;
  object-fit: cover;
`;

export default BlisterPhoto;
