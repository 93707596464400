import React, { ChangeEvent } from "react";
import styled from "@emotion/styled";
import useStoreon from "storeon/react";

import { Layout, Content, Footer } from "../../components/Layout";
import BlisterPhoto from "../../components/BlisterPhoto";
import { FileUploadButton, PlainButton } from "../../components/Button";
import { H2 } from "../../components/Typography/Heading";

import { StoreActions } from "../../store";

const AnalyzingPage = () => {
  const { dispatch, photoSrc } = useStoreon("photoSrc");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      dispatch(StoreActions.AnalyzeSecondBlister, e.target.files[0]);
    }
  };

  return (
    <Layout title="10 positions in blister">
      <Content>
        <H2>
          Taken: 3<br />
          Still to take: 7
        </H2>
        <BlisterPhoto src={photoSrc} />
      </Content>

      <Footer>
        <FileUploadButton onChange={onChange}>
          Scan blister now
        </FileUploadButton>
        <PlainButton style={{ marginTop: "15px" }}>
          The scan was not accurate
        </PlainButton>
      </Footer>
    </Layout>
  );
};

export default AnalyzingPage;
