import createStore, { Module } from "storeon";

import { analyzeFirstBlister, analyzeSecondBlister } from "../api/sendPhoto";

export enum Views {
  TakePill,
  Analyzing,
  FirstBlisterResult,
  SuccessSecondBlisterResult,
  FailedSecondBlisterResult,
  NextPill
}

export enum StoreActions {
  ChangeView,
  ChangePhotoSrc,
  AnalyzeFirstBlister,
  AnalyzeSecondBlister,
  End
}

type StateType = {
  view: Views;
  photoSrc: string;
};

const reducer: Module<StateType> = store => {
  store.on("@init", () => ({
    view: Views.TakePill,
    photoSrc: ""
  }));

  store.on(StoreActions.ChangeView, (state, payload: Views) => ({
    ...state,
    view: payload
  }));

  store.on(StoreActions.ChangePhotoSrc, (state, photoSrc: string) => ({
    ...state,
    photoSrc
  }));

  store.on(StoreActions.AnalyzeFirstBlister, async (state, file: File) => {
    const photoSrc = URL.createObjectURL(file);

    function dataURItoBlob(dataURI: any) {
      // convert base64 to raw binary data held in a string
      var byteString = atob(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to an ArrayBuffer
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
          _ia[i] = byteString.charCodeAt(i);
      }

      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], { type: mimeString });
      return blob;
    }


    let formdata= new FormData();
    formdata.append('file', file);

    fetch('http://localhost:63171/api/images', {
      method: 'post',
      body: formdata
     }).catch((resaon)=> console.log(resaon));



    debugger;
    store.dispatch(StoreActions.ChangePhotoSrc, photoSrc);
    store.dispatch(StoreActions.ChangeView, Views.Analyzing);

    const analyzedPhotoSrc = await analyzeFirstBlister(photoSrc);
    store.dispatch(StoreActions.ChangePhotoSrc, analyzedPhotoSrc);
    store.dispatch(StoreActions.ChangeView, Views.FirstBlisterResult);

    URL.revokeObjectURL(photoSrc);
  });

  store.on(StoreActions.AnalyzeSecondBlister, async (state, file: File) => {
    const photoSrc = URL.createObjectURL(file);
    store.dispatch(StoreActions.ChangePhotoSrc, photoSrc);
    store.dispatch(StoreActions.ChangeView, Views.Analyzing);

    let analyzedPhotoSrc;
    try {
      analyzedPhotoSrc = await analyzeSecondBlister(photoSrc);
      store.dispatch(StoreActions.ChangeView, Views.SuccessSecondBlisterResult);
    } catch (e) {
      if (typeof e === "string") {
        analyzedPhotoSrc = e;
        store.dispatch(
          StoreActions.ChangeView,
          Views.FailedSecondBlisterResult
        );
      } else {
        throw e;
      }
    }
    store.dispatch(StoreActions.ChangePhotoSrc, analyzedPhotoSrc);

    URL.revokeObjectURL(photoSrc);
  });

  store.on(StoreActions.End, state => ({
    ...state,
    view: Views.NextPill
  }));
};

const store = createStore<StateType>([reducer]);

export default store;
