import React, { ChangeEvent } from "react";
import styled from "@emotion/styled";

const Button = styled.button`
  background: linear-gradient(160.98deg, #4fb58d 10.79%, #60cca7 91.16%);
  box-shadow: 0px 10px 15px rgba(35, 156, 75, 0.1);
  border-radius: 32.5px;
  border: 0;
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  font-weight: 500;

  color: #ffffff;

  &:disabled {
    opacity: 0.2;
    background: linear-gradient(0deg, #212338, #212338),
      linear-gradient(160.98deg, #4fb58d 10.79%, #60cca7 91.16%);
  }
`;

type FileUploadButtonProps = {
  children: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};
const FileUploadWrapper = styled.div`
  position: relative;
`;
const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
`;
export const FileUploadButton = (props: FileUploadButtonProps) => {
  return (
    <FileUploadWrapper>
      <FileInput
        onChange={props.onChange}
        type="file"
        accept="image/*"
        capture="camera"
      />
      <Button>{props.children}</Button>
    </FileUploadWrapper>
  );
};

export const PlainButton = styled.button`
  border: 0;
  font-size: 13px;
  text-align: center;
  color: #3fa880;
  background: none;
  width: 100%;
`;

export default Button;
