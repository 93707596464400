import React from "react";
import styled from "@emotion/styled";
import useStoreon from "storeon/react";

import { Layout, Content, Footer } from "../../components/Layout";
import BlisterPhoto from "../../components/BlisterPhoto";
import { H2 } from "../../components/Typography/Heading";
import SpinnerBase from "../../components/Spinner";

const PhotoWrapper = styled.div`
  position: relative;
`;
const Darkener = styled.div`
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: -30px;
  width: 100vw;
`;
const Spinner = styled(SpinnerBase)`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const AnalyzingPage = () => {
  const { photoSrc } = useStoreon("photoSrc");

  return (
    <Layout title="My medicine 25mg">
      <Content>
        <H2>Analyzing...</H2>
        <PhotoWrapper>
          <BlisterPhoto src={photoSrc} />
          <Darkener />
          <Spinner />
        </PhotoWrapper>
      </Content>
    </Layout>
  );
};

export default AnalyzingPage;
