import React, { useState } from "react";
import useStoreon from "storeon/react";

import { Layout, Content, Footer } from "../../components/Layout";
import BlisterPhoto from "../../components/BlisterPhoto";
import { default as Button, PlainButton } from "../../components/Button";
import { H2 } from "../../components/Typography/Heading";
import Popup, { PopupTitle } from "../../components/Popup";
import { RadioOption } from "../../components/RadioInput";

import { StoreActions } from "../../store";

enum RadioValues {
  TookOne,
  TookTwo,
  LostTwo
}

const AnalyzingPage = () => {
  const [popupOpened, setPopupOpened] = useState(false);
  const [radioState, setRadioState] = useState<any>(null);

  const { dispatch, photoSrc } = useStoreon("photoSrc");

  const onFinish = () => {
    dispatch(StoreActions.End);
  };

  return (
    <Layout title="Pill might be missing">
      <Content>
        <H2>
          The amount of pills is
          <br />
          not what we expect
        </H2>
        <BlisterPhoto src={photoSrc} />
      </Content>

      {popupOpened ? (
        <Popup>
          <PopupTitle>
            Please define the
            <br />
            reason a pill missing
          </PopupTitle>

          <div style={{ marginTop: "20px" }}>
            <RadioOption
              name="a"
              onChange={setRadioState}
              text="I took one pill and lost one"
              checked={radioState === RadioValues.TookOne}
              value={RadioValues.TookOne}
            />
            <RadioOption
              name="a"
              onChange={setRadioState}
              text="I took two pills"
              checked={radioState === RadioValues.TookTwo}
              value={RadioValues.TookTwo}
            />
            <RadioOption
              name="a"
              onChange={setRadioState}
              text="I lost two pills"
              checked={radioState === RadioValues.LostTwo}
              value={RadioValues.LostTwo}
            />

            <Button
              disabled={radioState === null}
              onClick={onFinish}
              style={{ margin: "20px 0" }}
            >
              Next
            </Button>
          </div>
        </Popup>
      ) : null}

      <Footer>
        <Button onClick={() => setPopupOpened(true)}>
          Confirm missing pill
        </Button>
        <PlainButton style={{ marginTop: "15px" }}>
          The scan was not accurate
        </PlainButton>
      </Footer>
    </Layout>
  );
};

export default AnalyzingPage;
