import React from "react";
import StoreContext from "storeon/react/context";

import store from "./store";

import Router from "./Router";

function App() {
  return (
    <StoreContext.Provider value={store}>
      <Router />
    </StoreContext.Provider>
  );
}

export default App;
