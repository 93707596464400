import React from "react";
import useStoreon from "storeon/react";

import TakePill from "./views/TakeAPill";
import Analyzing from "./views/Analyzing";
import FirstBlisterResult from "./views/FirstBlisterResult";
import SuccessSecondBlisterResult from "./views/SuccessSecondBlisterResult";
import FailedSecondBlisterResult from "./views/FailedSecondBlisterResult";
import NextPill from "./views/NextPill";

import { Views } from "./store";

const Router = () => {
  const { view } = useStoreon("view");

  if (view === Views.TakePill) {
    return <TakePill />;
  } else if (view === Views.Analyzing) {
    return <Analyzing />;
  } else if (view === Views.FirstBlisterResult) {
    return <FirstBlisterResult />;
  } else if (view === Views.SuccessSecondBlisterResult) {
    return <SuccessSecondBlisterResult />;
  } else if (view === Views.FailedSecondBlisterResult) {
    return <FailedSecondBlisterResult />;
  } else if (view === Views.NextPill) {
    return <NextPill />;
  } else {
    return <div>Not found</div>;
  }
};

export default Router;
