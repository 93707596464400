import React from "react";
import useStoreon from "storeon/react";

import { Layout, Content, Footer } from "../../components/Layout";
import BlisterPhoto from "../../components/BlisterPhoto";
import { default as Button, PlainButton } from "../../components/Button";
import { H2 } from "../../components/Typography/Heading";

import { StoreActions } from "../../store";

const Page = () => {
  const { dispatch, photoSrc } = useStoreon("photoSrc");

  return (
    <Layout title="You have taken your pill">
      <Content>
        <H2>
          Well done! You have taken <br /> the pill as scheduled
        </H2>
        <BlisterPhoto src={photoSrc} />
      </Content>

      <Footer>
        <Button
          onClick={() => {
            dispatch(StoreActions.End);
          }}
        >
          Next
        </Button>
        <PlainButton style={{ marginTop: "15px" }}>
          The scan was not accurate
        </PlainButton>
      </Footer>
    </Layout>
  );
};

export default Page;
