// TODO: replace with real backend

import blister1 from "./blister1.png";
import blisterOk from "./blisterok.png";
import blisterBad from "./blisterbad.png";

export async function analyzeFirstBlister(src: string): Promise<string> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(blister1);
    }, 2500);
  });
}

export async function analyzeSecondBlister(src: string): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (window.location.search.indexOf("bad=true") !== -1) {
        reject(blisterBad);
      } else {
        resolve(blisterOk);
      }
    }, 2500);
  });
}
