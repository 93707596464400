import React, { ChangeEvent } from "react";
import styled from "@emotion/styled";

import checkSrc from "./Check.png";

const Input = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 36px;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
  }

  &:checked + label:before {
    content: "";
    display: block;
    position: absolute;
    left: -5px;
    top: 0;
    width: calc(100% + 10px);
    height: 100%;
    background: #cdf1e4;
    border-radius: 100px;
    z-index: -1;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 24px;
    height: 24px;
    background-image: url(${checkSrc});
  }

  &:not(:checked) + label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 24px;
    height: 24px;
    border: 2px solid rgba(33, 36, 56, 0.2);
    border-radius: 100%;
    background: #fff;
  }
`;
const Label = styled.label`
  z-index: 1;
`;

type OptionProps = {
  name: string;
  value: any;
  text: string;
  checked?: boolean;
  onChange: (value: any) => void;
};
export const RadioOption = (props: OptionProps) => {
  const { name, text, value, checked, onChange } = props;

  const onChangeHandler = () => {
    onChange(value);
  };

  return (
    <div onClick={onChangeHandler}>
      <Input
        onChange={onChangeHandler}
        type="radio"
        name={name}
        value={value}
        checked={checked}
      />
      <Label>{text}</Label>
    </div>
  );
};
