import React from "react";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";

import { ReactComponent as MenuIconBase } from "../../icons/icon-menu.svg";
import { ReactComponent as UserIconBase } from "../../icons/icon-user.svg";

const Wrapper = styled(Div100vh)`
  background: #f8f8f8;
  position: relative;
  padding: 21px 30px 0;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
`;
export const Content = styled.div``;
export const Footer = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
`;
const Title = styled.h1`
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  color: #212438;
`;
const MenuIcon = styled(MenuIconBase)``;
const UserIcon = styled(UserIconBase)``;

type LayoutProps = {
  title: string;
  children: any;
};

export const Layout = (props: LayoutProps) => {
  return (
    <Wrapper>
      <Header>
        <MenuIcon />
        <Title>{props.title}</Title>
        <UserIcon />
      </Header>

      {props.children}
    </Wrapper>
  );
};
