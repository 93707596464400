import React from "react";
import { createPortal } from "react-dom";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";

const Wrapper = styled(Div100vh)`
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(33, 35, 56, 0.8),
    rgba(33, 35, 56, 0.8)
  );
`;
const PopupWindow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 30px;
`;

export const PopupTitle = styled.h1`
  font-size: 24px;
  text-align: center;
  color: #212438;
  font-weight: bold;
  margin: 0;
`;

type PopupProps = {
  children: any;
};
const Popup = (props: PopupProps) => {
  return createPortal(
    <Wrapper>
      <PopupWindow>{props.children}</PopupWindow>
    </Wrapper>,
    document.getElementById("popup") as HTMLElement
  );
};

export default Popup;
