import React, { ChangeEvent } from "react";
import styled from "@emotion/styled";
import useStoreon from "storeon/react";

import { Layout, Content, Footer } from "../../components/Layout";
import { FileUploadButton, PlainButton } from "../../components/Button";
import blisterSrc from "./Blister.png";

import { StoreActions } from "../../store";

const PreHeaderText = styled.div`
  padding-top: 20px;
  font-size: 11px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #979797;
`;
const DateText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #212438;
  margin-top: 15px;
`;
const HelpText = styled.div`
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: rgba(33, 36, 56, 0.85);
`;
const BlisterPic = styled.img`
  margin: 0 auto;
  margin-top: 30px;
  display: block;
`;

const TakeAPill = () => {
  const { dispatch } = useStoreon();

  const onChange = (e: any) => {
    if (e.target.files) {
      dispatch(StoreActions.AnalyzeFirstBlister, e.target.files[0]);
    }
  };

  return (
    <Layout title="My medicine 25mg">
      <Content>
        <PreHeaderText>It's time to take your pill</PreHeaderText>
        <DateText>Evening Tuesday, 14 July</DateText>
        <HelpText>
          We help you to take your
          <br />
          medication at the right time.
        </HelpText>
        <BlisterPic src={blisterSrc} />
      </Content>
      <Footer>
        <FileUploadButton onChange={onChange}>
          Scan blister now
        </FileUploadButton>
        <PlainButton style={{ marginTop: "15px" }}>
          I am unable to scan now
        </PlainButton>
      </Footer>
    </Layout>
  );
};

export default TakeAPill;
